<template>
    <v-toolbar color="primary" style=" border-radius : 7px" class="white--text mb-3" >
        <template v-if="!isMobile">
            <v-toolbar-title>
                <span class=" text-capitalize">{{title}}</span>
            </v-toolbar-title>
            <v-divider
                    class="mx-4"
                    inset
                    vertical
            ></v-divider>
        </template>
        <v-text-field
                v-model="search"
                dark
                color="white"
                filled
                dense
                rounded
                append-icon="mdi-magnify"
                placeholder="Search"
                class="mt-7"
        />
        <v-spacer></v-spacer>
        <slot></slot>
    </v-toolbar>
</template>

<script>
    export default {
        name: "DataTableTop",
        props: {
            title: String,
        },
        watch: {
            search(value){
                this.$emit('search', value)
            }
        },
        data(){
            return {
                search: ''
            }
        }
    }
</script>

<style scoped>

</style>